<template>
	<div>
		<div>
			<div class="content1">
				<div class="all">
					<div class="SearchBar">
						<!--搜索框-->
						<el-form
							ref="search"
							:inline="true"
							:model="search"
							size="small"
							label-width="80px"
						>
							<el-form-item label="时间段">
								<el-date-picker
									v-model="times"
									type="datetimerange"
									range-separator="至"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									clearable
									value-format="yyyy-MM-dd HH:mm:ss"
									:default-time="['00:00:00', '23:59:59']"
									@change="getSearchDate"
								>
								</el-date-picker>
							</el-form-item>
							<el-form-item label="维修员">
								<el-input
									v-model="search.username"
									placeholder="请输入维修员姓名"
									class="SearchInput"
									clearable
								></el-input>
							</el-form-item>
							<el-form-item label-width="20px" label=" ">
								<div>
									<el-button
										type="primary"
										size="small"
										@click="getList(1)"
										>查询</el-button
									>
									<el-button
										type="warning"
										size="small"
										@click="reset"
										>重置</el-button
									>
								</div>
							</el-form-item>
						</el-form>
					</div>
					<div class="table">
						<!--数据表格-->
						<el-table
							ref="tableData"
							:data="tableData"
							style="width: 100%"
							border
							height="calc(100vh - 333px)"
							:stripe="true"
							:header-cell-style="{
								color: '#333',
								fontFamily: 'MicrosoftYaHeiUI',
								fontSize: '14px',
								fontWeight: 900,
								textAlign: 'center',
								background: '#f8f8f9',
							}"
						>
							<el-table-column
								type="index"
								label="序号"
								width="70"
								align="left"
							>
							</el-table-column>
							<el-table-column
								prop="startTime"
								label="日期"
								align="left"
								show-overflow-tooltip
							>
							</el-table-column>
							<el-table-column
								prop="userNamePhone"
								label="维修员"
								align="left"
								show-overflow-tooltip
							>
							</el-table-column>
							<el-table-column
								prop="startTime"
								label="上班时间"
								align="left"
								show-overflow-tooltip
							>
							</el-table-column>
							<el-table-column
								prop="endTime"
								label="下班时间"
								align="left"
								show-overflow-tooltip
							>
								<template slot-scope="scope">
									{{!scope.row.endTime? "--": scope.row.endTime}}
								</template>
							</el-table-column>
              <el-table-column
                  prop="patrolTimes"
                  label="巡检时长"
                  align="left"
                  show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <!-- {{(scope.row.scheduleTime/60).toFixed(2)}} <span>分钟</span> -->
                  {{parseInt(scope.row.patrolTimes/60/60%24)}} <span>小时</span>
                  {{parseInt(scope.row.patrolTimes/60%60)}} <span>分钟</span>
                  {{parseInt(scope.row.patrolTimes%60)}} <span>秒</span>
                </template>
              </el-table-column>
							<el-table-column
								prop="pauseDuration"
								label="暂停时长"
								align="left"
								show-overflow-tooltip
							>
							</el-table-column>
							<el-table-column
								prop="startElectric"
								label="开始电量"
								align="left"
								width="85"
								show-overflow-tooltip
							>
								<template slot-scope="{ row }">
									<span
										v-if="
											row.startElectric ||
											row.startElectric === 0
										"
									>
										{{ row.startElectric }}%
									</span>
								</template>
							</el-table-column>
							<el-table-column
								prop="endElectric"
								label="剩余电量"
								width="85"
								align="left"
								show-overflow-tooltip
							>
								<template slot-scope="{ row }">
									<span
										v-if="
											row.endElectric ||
											row.endElectric === 0
										"
									>
										{{ row.endElectric }}%
									</span>
								</template>
							</el-table-column>
							<el-table-column
								align="left"
								prop="projectNumber"
								label="操作"
								width="150"
								fixed="right"
							>
								<template slot-scope="{ row }">
									<el-button
										type="primary"
										size="mini"
										@click="showDetil(row)"
                    v-if="$anthButtons.getButtonAuth('gzgjckgj')"
										>查看轨迹</el-button
									>
								</template>
							</el-table-column>
						</el-table>
						<div class="pageBox">
							<el-pagination
								:current-page="search.current"
								:background="true"
								:page-sizes="[10, 30, 50, 100]"
								:page-size="search.size"
								layout="total, prev, pager, next, sizes, jumper"
								:total="total"
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
							>
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
		<map-locus ref="mapLocus"></map-locus>
	</div>
</template>

<script>
	import {
		pageWorktrackRecord,
		listWorktrackByRecordId,
	} from "@/RequestPort/workLocuskf";
	import mapLocus from "./mapLocus.vue";
	export default {
		components: { mapLocus },
		name: "workLocuskf",
		data() {
			return {
				search: {
					current: 1,
					size: 100,
					startTime: "",
					endTime: "",
					username: "",
				},
				times: [],
				tableData: [],
				total: 0,
			};
		},
		mounted() {
			this.getList(1);
		},
		methods: {
			getSearchDate() {
				if (this.times && this.times.length > 0) {
					this.search.startTime = this.times[0];
					this.search.endTime = this.times[1];
				} else {
					this.search.startTime = "";
					this.search.endTime = "";
				}
				// this.getList(1);
			},
			getList(page) {
				if (page) {
					this.search.current = 1;
				}
				pageWorktrackRecord(this.search).then((res) => {
					this.tableData = res.data.records;
					this.total = res.data.total;
				});
			},
			reset() {
				this.search = {
					current: 1,
					size: 100,
					startTime: "",
					endTime: "",
					username: "",
				};
				this.times = [];
				this.getList(1);
			},
			handleSizeChange(val) {
				this.search.size = val;
				this.getList(1);
			},
			handleCurrentChange(val) {
				this.search.current = val;
				this.getList();
			},
			showDetil(row) {
				listWorktrackByRecordId({ recordId: row.id }).then((res) => {
					this.$refs.mapLocus.open(row, res.data);
				});
				// this.$refs.mapLocus.open(row)
			},
		},
	};
</script>

<style scoped>
	.SearchBar {
		/* border: 1px solid #ecedf1; */
		border-radius: 5px;
		margin: 10px;
		padding-top: 1%;
		background-color: #ffffff;
	}
	.table {
		padding: 20px 20px 15px 20px !important;
		border-radius: 5px;
		margin: 10px;
		background-color: #ffffff;
		height: auto;
	}
	.el-pagination {
		margin: 15px 0px 0px;
	}
</style>